@import "react-big-calendar/lib/css/react-big-calendar.css";

body {
  background-color: #f9fafb;
  font-family: "Inter", sans-serif;
  color: #000;
  overflow-x: hidden;
}
a {
  color: #2342bf;
}
.text-blue {
  color: #2342bf;
}
section {
  padding: 40px 0;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-30 {
  font-size: 30px;
  line-height: normal;
  color: #0a0d14;
  padding: 0 0 8px;
}
.font-36 {
  font-size: 36px;
}
.font-36 .month-text {
  font-size: 20px;
}
.font-60 {
  font-size: 60px;
  line-height: normal;
}
.hd-title {
  font-weight: 600;
}
.hd-subtitle {
  font-weight: 400;
  color: #475467;
}
.btn:disabled {
  background: #c3c3c3;
  color: #000;
  border: 1px solid;
  cursor: not-allowed;
  pointer-events: unset !important;
}

.btn {
  font-weight: 600;
  font-size: 14px;
}
.widthHeight {
  height: 40px;
  height: 40px;
}
.btn-primary {
  background-color: #2342bf;
  border-color: #2342bf;
  min-width: 120px;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #1c3497;
  border-color: #1c3497;
}
.btn-outline-primary {
  border-color: #2342bf;
  color: #2342bf;
  min-width: 120px;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: #2342bf;
  color: #fff;
}
.btn-light {
  border-color: #d0d5dd;
  background-color: #fff;
}
.btn-light:hover,
.btn-light:focus {
  background-color: #f9fafb;
}
.btn-outline-danger {
  border-color: #f04438;
  color: #b42318;
  min-width: 120px;
}
.data_border {
  margin-right: 1px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.sap-card-header {
  min-height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d5dd;
  margin: 0 0 40px;
}
.sap-card {
  background-color: #fff;
  padding: 32px;
  border-radius: 12px;
}
.oas-sec .container,
.vpn-sec .container,
.ftl-inner {
  max-width: 800px;
}
.sap-alert-heading {
  background-color: #f5f8ff;
  border: 0;
  margin: 0;
}
.sap-accor-form {
  margin: 32px 0 0;
}
.saf-title-head .font-18 {
  font-weight: 600;
}
.saf-title-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sap-accor-form .accordion-button {
  padding: 32px 0;
  background-color: transparent;
  box-shadow: 0 0;
  outline: 0;
}
.saf-left {
  display: flex;
  align-items: center;
}
.saf-icon {
  margin: 0 16px 0 0;
}
.sap-accor-form .accordion-item {
  border-color: #d0d5dd;
}
.sap-card-footer .form-check-label,
.sap-card-footer .form-check-label a {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}
.form-check-input:checked {
  background-color: #2342bf;
  border-color: #2342bf;
}

.sap-card-footer .btn-primary {
  display: block;
  margin: 32px 0 0 auto;
}
button,
input,
optgroup,
select,
textarea {
  box-shadow: 0 0 !important;
  outline: 0 !important;
}
textarea {
  min-height: 110px !important;
  padding: 10px 12px !important;
  resize: none !important;
}
.accordion-body {
  padding: 0 0 16px;
}
.sap-card-footer {
  border-top: 1px solid #d0d5dd;
  padding: 32px 0 0;
}
.form-control {
  min-height: 44px;
}
.form-label {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 6px;
  line-height: normal;
}
.flex {
  display: flex;
}
.saf-img-upload {
  padding: 0 0 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.saf-img-block {
  overflow: hidden;
  border-radius: 12px;
}
.saf-img-upload .btn {
  min-width: 110px;
}

.sap-link {
  font-weight: 600;
  color: #2342bf;
}
.vpn-form {
  margin: 32px 0 16px;
  width: 100%;
  max-width: 540px;
  gap: 12px;
}
.vpn-sec .sap-card-body .dual-btn {
  padding: 32px 0 0;
  gap: 10px;
  display: flex;
}
.vpn-form input {
  font-size: 36px;
  font-weight: 400;
  border: 3px solid #d0d5dd;
  min-height: 80px;
  width: 100%;
}
.vpn-form input::placeholder {
  color: rgb(0 0 0 / 50%);
}
.vpn-form .col {
  padding: 0;
}

.coming-soon-block .hd-title {
  font-weight: 800;
  color: #2342bf;
}
.coming-soon-block {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.coming-soon-block .font-16 {
  display: flex;
  align-items: center;
  color: #000;
}
.coming-soon-block .font-16 span {
  margin: 8px 5px 0;
}
.navbar-brand img {
  height: 50px;
  /* border: solid 1px #ced4da; */
}
/* .navbar-brand .headerimg{width: 70%;} */
.navbar-expand-lg {
  background-color: #fff !important;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  padding: 15px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
.coming-soon-sec {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-expand-lg .navbar-nav {
  margin-left: auto;
  margin-right: 0 !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 40px 0 0;
}
header .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
header .nav-item .btn-primary {
  min-width: 100px;
}

.timezone {
  margin-top: 27px;
  line-height: 22px;
}

.subscriptions-page,
.subscriptions-step-form-page {
  margin: 80px 0 0;
}
.sub-plans-toggler {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 0;
  align-items: center;
}
.sub-plans-toggler form {
  background-color: #f2f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px;
  border-radius: 8px;
  min-height: 52px;
}
.sub-plans-toggler form input {
  display: none;
}
.sub-plans-toggler form .form-check {
  padding: 0;
}
.sub-plans-toggler form .form-check label {
  line-height: normal;
  padding: 10px 16px;
  cursor: pointer;
}
.sub-plans-toggler form .form-check input:checked[type="radio"] + label {
  background-color: #a99b9bba;
  border: 1px solid #2342bf;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);
}
.sub-plans-toggler form .form-check * {
  transition: all 0.3s ease-in-out;
}
.sub-plans-head {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 32px 0;
  gap: 16px;
  text-align: center;
}
.sub-plans-inner {
  padding: 24px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 16px;
  position: relative;
}
.sub-plans-inner:nth-child(2) {
  background-color: #f5f8ff;
  border-color: #2342bf;
  border: 1px solid #d0d5dd;
}
.sub-plans-inner .btn-outline-primary {
  width: 100%;
  margin: 0 0 48px;
}
.sub-plans-inner .font-20 {
  padding: 0 0 4px;
}
.sub-plans-inner .badge.text-bg-success,
.sub-payment-month-head .text-bg-success {
  background-color: #dcfae6 !important;
  border: 1px solid #079455;
  color: #344054 !important;
  font-weight: 500;
  margin: 0 0 0 5px;
  min-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.splh-inner {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.sub-plans-list-head {
  display: grid;
  grid-template-columns: 100%;
  gap: 12px;
}
.subscriptions-inner {
  max-width: 1032px;
}
.subscriptions-inner a,
.dash-billing-plan a {
  text-align: center;
  display: block;
  color: #2342bf;
  font-weight: 400;
  text-decoration: none;
}
.sub-plans-inner .font-36 {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-btn {
  text-align: right;
}
.first-time-login-form {
  margin-top: 32px;
}

.modal-header-head .modal-header-logo img,
.sub-step-header-logo img {
  height: 35px;
  width: auto;
}
.modal-header-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.modal-header-head .modal-title {
  font-size: 16px !important;
  line-height: normal;
  width: 100%;
  max-width: 160px;
}
.text-bg-primary {
  background-color: #2342bf !important;
}
.text-bg-primary {
  background-color: #2342bf !important;
  color: #fff !important;
}
.lih-inner {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
  padding: 0 0 10px;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}
.license-info-head .badge,
.sfpp-inner .text-bg-success,
.sub-payment .font-16 .text-bg-success {
  background-color: #dcfae6 !important;
  border: 1px solid #079455;
  color: #344054 !important;
  font-weight: 600;
  min-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.buy-more-license-link {
  width: 100%;
  text-align: center;
  display: block;
  margin: 15px auto 0;
  cursor: pointer;
}
.lih-inner .font-16 {
  line-height: normal;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.lih-third .badge {
  background-color: rgb(255 193 7 / 25%) !important;
  border: 1px solid #ffc107;
}
.lih-forth .badge {
  background-color: rgb(35 66 191 / 25%) !important;
  border: 1px solid rgb(35 66 191);
}
.dual-text {
  display: flex;
}
.sub-payment .dual-text {
  justify-content: space-between;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}
.sub-sf-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 24px;
}
.sub-step-header-logo {
  margin-bottom: 12px;
}
.sub-payment-month-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 12px;
}
.sub-payment .form-check .form-check-label {
  width: 100%;
}
.form-check .form-check-label {
  cursor: pointer;
}
.spmh-text {
  text-align: right;
}
.sub-payment .form-check {
  padding-left: 35px;
}

.congrats-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 510px;
  margin: 0 auto;
}
.congrats-block .fa-circle-check {
  font-size: 52px;
  color: #079455;
}
.dual-btn {
  display: flex;
  gap: 10px;
}
.form-complete .accordion-button::after {
  display: none;
}
.saf-right {
  visibility: hidden;
}
.form-complete .saf-right {
  visibility: visible;
}

.step-counter-head ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 50px;
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 24px;
}
.step-counter-head ul span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 4px;
  background-color: rgb(217 223 242);
  border-radius: 50%;
  border: 1px solid #2342bf;
  color: #2342bf;
  font-weight: 700;
}
.step-counter-head ul .active span {
  background-color: #079455;
  color: #fff;
  border-color: #079455;
}
.step-counter-head ul:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 320px;
  display: block;
  background-image: linear-gradient(to right, #2342bf 40%, transparent 0%);
  background-position: bottom;
  background-size: 12px 1px;
  background-repeat: repeat-x;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.step-counter-head ul li {
  position: relative;
  cursor: pointer;
}
.subscriptions-sf-inner {
  max-width: 800px;
}

.sub-form-pricing-panel .sfpp-inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.sfpp-right {
  display: flex;
  align-items: center;
}
.qty-head {
  display: flex;
}
.qty-head .btn-primary {
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  font-size: 20px;
}
.qty-head .form-control {
  max-width: 60px;
  margin: 0 8px 0;
  text-align: center;
}
.qty-price {
  padding: 0 0 0 40px;
  text-align: right;
  min-width: 120px;
}
.qty-price .hd-subtitle {
  line-height: normal;
}
.error {
  color: red;
}
.sub-plans-inner .font-36 .month-text {
  margin: 0 0 -4px 5px;
}
.tooltipp {
  position: relative;
  display: inline-block;
}
.tooltipp:hover .tooltipptext {
  visibility: visible;
}
.tooltipp .tooltipptext {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  border-radius: 5px;
  padding: 2px 10px;
  position: absolute;
  z-index: 1;
  font-size: 10px;
  top: -20px;
  left: -40px;
  right: 0;
  width: max-content;
  text-align: center;
}
.rbc-btn-group {
  /* display: none !important; */
}
tr.hover-row:hover .Profile_curve2.child-div {
  border-color: #ececec !important; /* Change the border color */
  background-color: #ececec !important; /* Optional: Add background color for extra visibility */
}
tr.hover-row:hover .border-white.child-div {
  border-color: #ececec !important; /* Change the border color */
}
tr.hover-row:hover .Profile_curve2.child-div {
  border-color: #e6e7e8 !important; /* Change the border color */
  background-color: #e6e7e8 !important; /* Optional: Add background color for extra visibility */
}
tr.hover-row:hover .border-white.child-div {
  border-color: #e6e7e8 !important; /* Change the border color */
}

.subscriptions-sf-inner .step-counter-head {
  background: #fff;
  padding: 20px 0px 20px;
  margin: 0 0 30px;
  border-radius: 15px;
  border: 1px solid #d0d5dd;
}
.subscriptions-sf-inner .step-counter-head ul {
  margin: 0 auto;
}
.subscriptions-sf-inner form {
  background: #fff;
  padding: 30px 20px;
  border-radius: 15px;
  border: 1px solid #d0d5dd;
}
.sub-form-pricing-panel .sfpp-inner .hd-title {
  display: flex;
  align-items: center;
  justify-content: end;
}
.sub-form-pricing-panel .sfpp-inner .hd-title p.plan-type {
  margin: 0 10px 0 0;
}
.step-back .sub-sf-header {
  background: #eff4ff;
  padding: 24px 0px;
  border-radius: 7px;
}
span.month {
  align-items: center;
  background-color: #dcfae6 !important;
  border: 1px solid #079455;
  color: #344054 !important;
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  min-height: 28px;
  text-align: center;
  font-size: 12px;
  padding: 0 9px;
  border-radius: 5px;
}

/* =====Dashboard css code======== */

.table-btn-sec .pagination a.page-link {
  color: #344054;
  font-size: 14px;
  padding: 9px 14px;
}
.table-btn-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px;
  width: 100%;
}
.table-btn-sec .pagination {
  margin: 0;
}
.table-btn-sec button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(52, 64, 84, 1);
}
.table-btn-sec button svg {
  margin: 0 5px 0 0;
  width: 18px;
  height: 18px;
}
.graph-content {
  display: block;
  border: 1px solid rgba(234, 236, 240, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 25px 25px;
  border-radius: 12px;
}
.g-content-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
}
.progrss-sec {
  display: flex;
  align-items: center;
}
.progrss-sec p {
  margin: 0 0 0 1px;
  color: rgba(71, 84, 103, 1);
}
.progrss-sec p span {
  color: rgba(6, 118, 71, 1);
  margin: 0 5px 0 0;
}
.progrss-sec p span.red {
  color: rgba(180, 35, 24, 1);
}
.charts {
  margin: 0 0 50px;
}
.home-staff-create.graph-content {
  margin: 30px 0 0;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
.home-staff-create.graph-content svg {
  width: 48px;
  height: 48px;
  padding: 10px;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 10px;
}
.search-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchbar {
  position: relative;
}
.searchbar input {
  font-size: 14px;
  width: 300px;
  border: 1px solid rgba(208, 213, 221, 1);
  padding: 0 0 0 36px;
  height: 44px;
}
.searchbar svg {
  position: absolute;
  left: 11px;
  top: 14px;
}
.left-side-content p {
  margin: 0;
}
.g-content-box .content-box {
  width: 50%;
}
.table-profile {
  display: flex;
  align-items: center;
  /* padding: 10px 10px; */
  /* margin: 0 20px 10px 0px; */
  margin-bottom: 10px;
  padding-left: 10px;
}
.table-profile.active {
  border-left: #1043ba 2px solid;
  background: #ddedfb;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-bottom: 8px;
  padding-top: 8px;
}
.table-profile .profile-sec {
  display: flex;
  align-items: center;
}
.table-profile .profile-sec .name {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  border-radius: 45px;
}
.yellow {
  background: rgba(254, 240, 199, 1);
  color: rgba(122, 46, 14, 1);
}
.green {
  background: rgba(211, 248, 223, 1);
  color: rgba(8, 76, 46, 1);
}
.blue {
  background: rgba(209, 224, 255, 1);
  color: rgba(0, 53, 158, 1);
}
.badges {
  border: 1px solid rgba(208, 213, 221, 1);
  display: inline-block;
  padding: 4px 10px;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}
.table-sec .table td,
.table-sec .table th {
  vertical-align: middle;
  padding: 14px;
  text-transform: capitalize;
  white-space: nowrap;
}
.table-sec .table td:last-child {
  text-transform: lowercase;
}
.badges span {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: grey;
  border-radius: 50%;
  margin: 0 5px 0 0;
}
.table-sec .table th {
  font-size: 14px;
  color: rgba(71, 84, 103, 1);
  font-weight: 600;
  background: rgba(249, 250, 251, 1);
}
.pending {
  background: rgba(181, 71, 8, 1) !important;
}
.actives {
  background: rgba(23, 178, 106, 1) !important;
}
.closed {
  background: rgba(180, 35, 24, 1) !important;
}
.table-sec {
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 10px;
  overflow: hidden;
}
.table-sec .table {
  margin: 0;
}
.td-form-check {
  display: flex;
  align-items: center;
}
.btn svg {
  margin: 0 6px 0 0;
  width: 21px;
}
.table-sec .table td:first-child {
  cursor: pointer;
}
.breadcrumb-item a {
  text-decoration: none;
  color: #667085;
}
.breadcrumb-item {
  cursor: pointer;
}
.breadcrumb .active {
  cursor: pointer;
}
.table-sec .table tr:last-child td {
  border-bottom-width: 0;
}
.paging {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-checkbox label {
  display: flex;
  align-items: center;
}
.empty-sec {
  text-align: center;
  margin: 0 auto;
}
.empty-sec img {
  width: 25%;
  object-fit: contain;
  margin: 0 0 30px;
}
.empty-sec button {
  margin: 25px 0 0;
}
.pop-action-btn {
  text-align: right;
  margin: 30px 0 0;
}
.modal-dialog .modal-header {
  border: 0;
}
.close-deactivate {
  justify-content: space-between;
  margin-bottom: 20px;
}
.left-side-content a.link {
  display: inline-flex;
  align-items: center;
}
.blling-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between; /* border-bottom: 1px solid #ddd; */
  margin: 0 0 30px;
  padding: 30px 0;
  background: #f9fafb;
  border-radius: 16px;
}
.blling-tabs #pills-tab {
  margin: 0 auto 0 !important;
  gap: 10px;
}
.blling-tabs button {
  border-radius: 8px !important;
  padding: 12px 20px;
  color: #000;
  background-color: #fff !important;
  border: 1px solid #d0d5dd !important;
  text-transform: capitalize;
}
.blling-tabs ul {
  /* background-color: #F2F4F7; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px;
  border-radius: 8px;
  min-height: 52px;
}
.blling-tabs button.active {
  color: #2342bf !important; /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%) !important; */
  background-color: #f5f8ff !important;
  border: 1px solid #2342bf;
}
.license-info-head.bil-lic-sec .buynow {
  margin-left: auto;
  display: block;
  margin-top: 30px;
}
.table-btn-sec.more-filter {
  flex-wrap: wrap;
}
.table-btn-sec.more-filter .filter-option {
  width: 100%;
  display: flex;
  align-items: self-start;
  margin: 20px 0 0;
  gap: 14px;
}
.table-btn-sec.more-filter .searchbar svg {
  top: 40px;
}
.table-btn-sec.more-filter select {
  height: 44px;
}
.table-btn-sec.more-filter .filter-option .form-group {
  width: 25%;
}
.table-btn-sec.more-filter .filter-option .form-group input {
  width: 100%;
}
.debit-card {
  width: 336px;
  height: 214px;
  border-radius: 10px;
  background-color: #022c6a;
  box-shadow: 0 6px 10px -2px rgba(0, 0, 0, 0.25);
  padding: 30px;
  margin: 0px 0 20px;
  cursor: pointer;
}
.debit-card .master-card-logo {
  width: 50px;
}
.debit-card .card-number-block {
  display: flex;
  flex-wrap: wrap;
  padding: 23px 0;
}
.debit-card .card-number-block .number-block {
  text-align: center;
  background-color: #022c6a;
  border: none;
  outline: none;
  margin: 7px;
  width: 58px;
  color: #fff;
  font-family: "Encode Sans", sans-serif;
  letter-spacing: 3px;
  font-size: 20px;
}
.debit-card .card-number-block .number-block::placeholder {
  color: #607d8b;
}
.debit-card .card-number-block .number-block:nth-child(1) {
  margin-left: 0;
}
.debit-card .card-number-block .number-block:nth-child(4) {
  margin-right: 0;
}
.debit-card .card-holder-block .block-lebel {
  color: #607d8b;
  font-family: "Encode Sans", sans-serif;
  font-size: 10px;
}
.debit-card .card-holder-block .card-holder-name {
  font-family: "Encode Sans", sans-serif;
  color: #fff;
  background-color: #022c6a;
  border: none;
  outline: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
}
.debit-card .card-holder-block .card-holder-name::placeholder {
  color: #607d8b;
}
.debit-main-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.debit-main-sec a {
  text-align: center;
  display: block;
  width: 100%;
  margin: 10px 0;
}
.debit-card.active {
  box-shadow: none;
  border: 5px solid #ff692e;
  padding: 25px;
}
.cancel-subscription .modal-body span {
  color: red;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 20px;
}
.cancel-subscription .modal-body .note_text {
  margin-top: 10px;
}
.cancel-subscription .modal-body .reason-sec {
  margin: 20px 0 0;
}
.cancel-subscription .modal-body .reason-sec select {
  margin: 0 0 15px;
}
.modal-footer-action {
  margin: 15px 0 0;
  text-align: right;
}
.blling-tabs button svg {
  text-align: center;
  width: 30px;
  height: auto;
  margin: 0 auto;
}
.blling-tabs button.active svg path {
  fill: #2342bf;
}
.sort_icon {
  cursor: pointer;
}
.sort_icon.asc svg {
  transform: unset;
}
.sort_icon.desc svg {
  transform: rotate(180deg);
}
.dashboard_tab {
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 20px;
  border-radius: 12px;
}
.td_upperCase {
  text-transform: uppercase !important;
}
.dashboard_no_result {
  text-align: center;
  margin: 40px;
}
.billing_no_card {
  text-align: center;
}
/* =====Dashboard css code======== */

/* =====Setting css code======== */

.sb-sidenav .sb-sidenav-menu .nav .nav-link:hover {
  background: #2342bf;
  color: #fff;
  opacity: 1;
}
.sb-nav-fixed
  #layoutSidenav
  #layoutSidenav_nav
  .sb-sidenav
  .sb-sidenav-menu::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}
.sb-nav-fixed
  #layoutSidenav
  #layoutSidenav_nav
  .sb-sidenav
  .sb-sidenav-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35) inset;
  border-radius: 10px;
}
.sb-nav-fixed
  #layoutSidenav
  #layoutSidenav_nav
  .sb-sidenav
  .sb-sidenav-menu::-webkit-scrollbar-thumb {
  background: #2342bf;
  border-radius: 10px;
}
.sb-nav-fixed
  #layoutSidenav
  #layoutSidenav_nav
  .sb-sidenav
  .sb-sidenav-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.nav-item.dropdown a {
  height: 40px;
  border-radius: 50%;
  padding: 0 !important;
  margin: 0 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}
.nav-item.dropdown.profile a img {
  width: 40px;
  margin: 0 0 0 10px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #ced4da;
}
.nav-item.dropdown a:after {
  display: none;
}
.nav-item.dropdown ul.dropdown-menu.dropdown-menu-end.show a {
  width: auto;
  height: auto;
  border: 0;
  display: block;
  border-radius: 0;
  padding: 5px !important;
  color: #444;
  font-weight: 500;
  margin: 0 11px;
}
.notification {
  position: relative;
}
.notification span {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #1de131;
  border-radius: 50%;
  right: 22px;
  top: 7px;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link svg {
  margin: 0 0 0 5px;
  margin-left: auto;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon svg {
  margin: 0;
}
.myheader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background: #f9fafb;
  height: 125px;
}
.nav-item.dropdown a svg {
  width: 28px;
  height: 28px;
}
.nav-item.dropdown ul.dropdown-menu.dropdown-menu-end.show a:hover {
  background: #fff;
  color: #000;
  font-weight: 500;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link:hover .sb-nav-link-icon svg path {
  fill: #fff;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link:hover .sb-sidenav-collapse-arrow {
  color: #fff;
}
.sb-sidenav-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.upgrade-sec {
  background: #f9fafb;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  margin: 10px 0 0;
}
.icon {
  margin: 0 0 16px;
}
.upgrade-sec h3 {
  color: #101828;
  font-size: 14px;
  font-weight: 600;
}
.upgrade-sec p {
  color: #475467;
  font-size: 14px;
}
.upgrade-sec .action-links a {
  color: #475467;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  margin: 0 15px 0 0px;
}
.upgrade-sec .action-links a:hover {
  color: #2342bf;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.right-main-sec {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
}
.right-main-header h2 {
  color: #0a0d14;
  font-size: 24px;
  font-weight: 600;
}
.right-main-header {
  margin: 30px 0;
}
main {
  padding: 0 10px 0 30px;
}
.sb-nav-fixed.sb-sidenav-toggled #layoutSidenav_content main {
  padding: 0 10px 0 0px;
}
.nav-tabs button.active {
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #2342bf !important;
}
.nav-tabs button {
  padding-left: 0;
  padding-right: 0;
  margin: 0 40px 0 0;
  color: #0a281d !important;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #fff;
  border-bottom-color: #dee2e6;
}
.nav-tabs {
  padding: 0 0 0px;
  margin: 0 0 30px;
}
.role-listing ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
}
.role-listing ul li a {
  width: 25px;
  height: 25px;
  display: inline-flex;
  font-size: 10px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: #f2f4f7;
  border: 1.5px solid #fff;
}
.role-listing ul li {
  margin: 0 0 0 -8px;
}
.role-listing {
  background: #f5f8ff;
  padding: 24px;
  border-radius: 15px;
  margin: 0 0 20px;
  text-transform: capitalize;
}
.role-listing a {
  text-decoration: none;
  color: #000;
}

.switch-tab-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 32px;
}
.switch-tab-details .switch-tabs .hd-title {
  text-transform: capitalize;
}
.switch-tab-details .switch-tabs .hd-subtitle {
  text-transform: capitalize;
}
.form-switch input {
  width: 44px !important;
  height: 24px;
}
.accordion.accordion-flush.sap-accor-form.popup {
  margin: 0;
}
.permision-popup {
  max-width: 750px;
}
.sb-sidenav
  .sb-sidenav-menu
  .nav
  .nav-link:hover
  .sb-nav-link-icon
  svg
  path:last-child {
  fill: darkgrey;
}
.modal-body.role-popup .login-btn button {
  width: 48%;
}
.modal-body.role-popup .login-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 5px 0px;
}
.billing-popup-sec {
  background: #f9fafb;
  max-width: 96%;
  margin: 0 auto 20px;
  border-radius: 10px;
  padding: 15px 10px;
}
.billing-popup-sec .form-check {
  background: #fff;
  padding: 15px 35px;
  border-radius: 8px;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
}
.permision-popup .modal-header {
  border: 0;
  margin: 0 0 0px;
  padding: 0;
}
.billing-popup-sec .form-check .sub-payment-month-head .hd-title {
  display: flex;
  align-items: center;
  justify-content: end;
}
.billing-popup-sec .form-check .sub-payment-month-head {
  margin: 0;
  padding: 0 0 0 5px;
}
.accordion-header.none button:after {
  display: none;
}
.pass-sec {
  position: relative;
}
.pass-sec svg {
  position: absolute;
  right: 0;
  width: 40px;
  height: 44px;
  background: #ddd;
  padding: 10px;
  top: 0;
  border-radius: 0 7px 7px 0;
  border: 1px solid #ccc;
}
.hd-title svg {
  width: 44px;
  height: 44px;
  background: rgba(239, 244, 255, 1);
  padding: 8px;
  border-radius: 6px;
  margin: 0 10px 0 0;
}
.hd-title svg path {
  fill: rgba(35, 66, 191, 1);
}
.ftl-sec {
  padding: 130px 0 50px;
}
.vpn-sec {
  padding: 120px 0 50px;
}
.oas-sec {
  margin: 120px 0 50px;
}
.error-sec {
  color: red;
  font-size: 12px;
}
.badge.text-bg-success.active-plan {
  background: #079455 !important;
  color: #fff !important;
  position: absolute;
  left: 5px;
  top: -13px;
  padding: 0 15px;
  font-size: 10px;
  font-weight: bold !important;
  border-radius: 5px;
  right: 0;
  max-width: max-content;
  margin: 0 auto !important;
}

/* deginer 2 changes */
/* .sb-sidenav .sb-sidenav-menu {flex-grow: 0;}
#exampleModalLabel svg {
    margin-left: -24px;
} */
.next-cycle {
  font-size: 13px;
  font-weight: 500;
  margin-left: 3px;
}
.modal-header.role-icon {
  justify-content: end;
}
.modal-ripple {
  position: absolute;
  left: -20px;
  top: -20px;
  z-index: 9;
}
.sub-payment.row form {
  padding: 0;
}
.permision-popup.payment-popup .billing-popup-sec {
  max-width: 100%;
  padding: 15px 15px;
}
.form-check.selectall {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  justify-content: start;
  padding: 0;
}

.pills-steps {
  display: flex;
  align-items: self-start;
}
.pills-steps .nav-link {
  text-align: left;
  border-radius: 0;
  min-height: 78px;
  color: #0a0d14;
  background: #fff;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #eaecf0;
  min-width: 400px;
  padding: 15px 24px;
}
.pills-steps .nav-link svg {
  width: 24px;
  margin: 0 5px 0 0;
}
.pills-steps .nav-link.active {
  background: #f5f8ff;
  color: #0a0d14;
}
.pills-steps .nav-link.active.show-actie-tab {
  background: #0d6efd36 !important;
}
.pills-steps .nav-link.active svg path {
  stroke: #099250;
  fill: #d3f8df;
}
.pills-steps .tab-content {
  width: 100%;
  background: #fff;
  padding: 24px;
  border: 1px solid #eaecf0;
}

/* =====1 july css======== */
.modal-header.role-icon.payment .logo-popup img {
  max-width: 130px;
}
.modal-header.role-icon.payment {
  justify-content: space-between;
}
.modal-header.role-icon.payment .plan-name {
  font-weight: 700;
  font-size: 20px;
}
.cancel-link {
  text-align: center;
  cursor: pointer;
}
.payment-popup .accordion-button {
  padding: 12px 0;
}
.payment-popup .role-icon.payment {
  margin: 0;
}
.pills-steps .sap-accor-form {
  margin: 0;
}
.searchsec {
  position: relative;
}
.searchsec input {
  padding: 0 0 0 36px;
  height: 44px;
}
.searchsec svg {
  position: absolute;
  left: 11px;
  top: 14px;
}
.searchsec svg.help {
  right: 10px;
  left: auto;
}
.three-btn-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-user-sec button img {
  margin: 0 5px 0 0px;
}
.home-user-sec button {
  margin: 0 3px 7px 0px;
  background: #f5f8ff;
  border: 0;
}
button.clear-filter {
  background: transparent;
  border: 0;
  text-decoration: underline;
  color: #98a2b3;
}
.profile-sec {
  display: flex;
  align-items: center;
  justify-content: start;
}
.profile-sec span.name {
  display: inline-block;
  width: auto;
}
.profile-sec span.name img {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%;
  object-fit: cover;
}
.role-listing ul img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-left: 8px;
}
.sap-card-header.progress-bar-sec {
  background: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px;
  margin: 0;
}
.steps-progress {
  display: flex;
  align-items: center;
  width: 70%;
}
.steps-progress .sap-card-title {
  margin: 0 10px 0 10px;
  font-size: 14px;
  white-space: nowrap;
}
.steps-progress .progress {
  width: 100%;
  height: 6px;
}
.steps-progress .progress .progress-bar {
  font-size: 0;
}
.member_health_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
}
.member_health_head h4 {
  margin: 0;
  color: #0a0d14;
  font-size: 18px;
  font-weight: 600;
}
.add-img-sec {
  display: flex;
  align-items: center;
  gap: 10px;
}
.add-img-sec img {
  width: 25px;
  border-radius: 36px;
  height: 25px;
  object-fit: cover;
}
.css-1p3m7a8-multiValue {
  background: #f5f8ff !important;
  border-radius: 5px;
}
.delete-link {
  text-transform: capitalize;
}
.tooltipp:hover .tooltipptext ul {
  padding: 5px;
  list-style: none;
  text-align: left;
  margin: 0;
}
.tooltipp:hover .tooltipptext ul li {
  padding: 5px 0 5px;
}
.profile-sec span.name {
  width: 30px;
  height: 30px;
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0 0px;
  font-weight: 700;
}

.border-white {
  border: 4px solid white;
  border-radius: 100%;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link.active-menu {
  background: #2342bf;
  color: #fff;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  padding: 0 10px 0 0;
}
.search-home-button {
  position: relative;
  top: 3px;
  min-height: 44px;
}
.congrats-block.sad-block i {
  font-size: 40px;
  color: red;
}
.home-users {
  display: inline-flex;
  align-items: center;
  gap: 0px;
}
.home-user-sec {
  margin-top: 15px;
}
/* =====1 july css======== */

/* ==========================member table css code 18 july============ */
.member_table_sec .table-btn-sec.more-filter .filter-option .form-group {
  width: 50%;
}
.mavtar_img img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  object-fit: cover;
  border: 1px solid #ccc;
}
.mavtar_img .name {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  font-size: 25px;
  margin: auto;
  padding-top: 46px;
}
.mjohan_cont p {
  color: #475467;
  font-weight: 400;
  margin: 20px auto;
  width: 50%;
}
.mjohan_cont h4 {
  color: #101828;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 4px 0;
}
.mjohan_cont {
  text-align: center;
  position: relative;
}
.mjohan_cont button {
  border: 1px solid #f04438;
  padding: 10px 16px;
  color: #b42318;
}
.mjohan_cont button:nth-child(2) {
  color: #fff;
  border: 1px solid #2342bf;
  margin: 0 0 0 10px;
}
.mjohan_cont button:nth-child(3) {
  color: #fff;
  border: 1px solid #2342bf;
  margin: 0 0 0 10px;
}
.mjohan_inner {
  position: absolute;
  top: 0;
  right: 0;
}
.login-btn button:hover svg path {
  stroke: #fff;
}
.vit_info_data .table-sec .table td:last-child {
  text-transform: none;
}
.vit_info_data .table-sec .table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  color: #101828;
}
.vit_info_data .table-sec .table tbody tr td span {
  color: #475467;
}
.vit_info_data .table-sec .table tbody tr td a {
  color: #2342bf;
  font-weight: 600;
}
.badges .pend {
  background-color: grey;
}
.badges .overdue {
  background-color: #b42318;
}
.vital_member_sec .saf-img-upload {
  justify-content: space-between;
}
.member_day_btn {
  display: flex;
}
.member_day_main {
  display: flex;
  align-items: center;
  justify-content: end;
}
.member_day_btn button:nth-child(3) {
  border-radius: 0px;
  min-width: 88px !important;
}
.member_day_btn button:nth-child(2) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.member_day_btn button:nth-child(4) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 0;
}
.member_day_btn select {
  border: none;
  margin: 0 0 0 10px;
}
.member_day_btn button:nth-child(1) {
  min-width: 60px;
  border: none;
}
.member_day_btn button {
  min-width: 70px !important;
}
.vital_member_sec .vital_comn .table-sec .table tbody tr td:last-child {
  text-transform: uppercase !important;
}
.vital_member_sec .vital_comn .table-sec .table tbody tr td:first-child {
  font-weight: 500;
  color: #101828;
}
.vital_member_sec .vital_comn .table-sec .table tbody tr td:first-child span {
  font-weight: 500;
  color: #475467;
}
.mvital_pdf_btn button {
  border: 1px solid #eaecf0;
  background-color: #fff;
  border-radius: 15px;
  padding: 5px 16px;
  margin: 12px 0 0 0;
}
.mvital_pdf_btn button:last-child {
  margin-left: 12px;
}
.upload_icon {
  border: 1px solid #eaecf0;
  margin: 0 auto 12px auto;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.vital_text_png {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
}
.vital_text_png span {
  color: #2342bf;
  font-weight: 600;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.vitalu_cont {
  border: 1px solid gray;
  background-color: #f9fafb;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  margin: 0 0 15px 0;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 100px;
  opacity: 0;
}
.up_vitalm_btn button {
  padding: 8px 38px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #344054;
  margin: 28px 0 0 0;
}
.up_vitalm_btn button:last-child {
  background-color: #2342bf;
  color: #ffffff;
  margin-left: 12px;
}
.progress_bar {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  border: 1px solid #eaecf0;
  padding: 16px;
  border-radius: 15px;
}

.doc_img {
  width: 40px;
  height: 40px;
  position: relative;
  bottom: 8px;
}

.doc_img img {
  width: 100%;
  height: 100%;
}

.progress_bar .progress_pdf {
  width: 50px;
}
.image_data {
  display: flex;
  text-align: start;
  gap: 4px;
  flex-direction: column;
}
.progress_bar .progress_pdf img {
  width: 100%;
}
.progres_file_cont {
  display: flex;
  justify-content: space-between;
}
.progres_file {
  width: 100%;
  margin: 0 0 0 12px;
}
.progres_file_cont h4 span {
  display: block;
  color: #475467;
}
.progres_file_cont h4 {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}
.progres_line_blue {
  background-color: #2342bf;
  height: 8px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
}
.progress_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.progress_line h4 {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
  margin: 0 0 0 10px;
}
.upload_modal_img_cont .owl-carousel .owl-nav.disabled {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.upload_moadal_inner {
  background: #f2f4f7;
  padding: 48px 80px;
}
.upload_modal_img_cont {
  padding: 0;
}
.member_upload_btn {
  padding: 16px;
  display: flex;
}
.member_upload_btn button {
  width: 50%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  color: #344054;
}
.member_upload_btn button:last-child {
  margin: 0 0 0 12px;
}
.upload_modal_img_cont .owl-carousel .owl-nav button span {
  font-size: 0;
}
.upload_modal_img_cont .owl-carousel .owl-nav .owl-prev::before {
  position: absolute;
  content: " ";
  height: 32px;
  width: 32px;
  left: -60px;
}
.upload_modal_img_cont .owl-carousel .owl-nav .owl-next::after {
  position: absolute;
  content: " ";
  height: 32px;
  width: 32px;
  right: -60px;
}

/* .upload_modal_img_cont .owl-carousel .owl-nav .owl-prev::before{position: absolute;content: " ";  background: url("../images/prevarrow.png") no-repeat;height: 32px;width: 32px;left: -60px;}
.upload_modal_img_cont .owl-carousel .owl-nav .owl-next::after{position: absolute;content: " ";  background: url("../images/nextarrow.png") no-repeat;height: 32px;width: 32px;right: -60px;} */

.medication_modal .mjohan_contt {
  display: flex;
  align-items: center;
  gap: 20px;
}
.medication_modal .mjohan_contt .pop-up-imgcontent {
  text-align: left;
}
.medication_modal .mjohan_contt .saf-img-upload.edit-member-sec {
  margin: 0;
  padding: 0;
}
.permision-popup .payment .cros_button button.btn-close {
  position: unset !important;
}
.permision-popup .modal-header button.btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}
.memb_mult_field_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d0d5dd;
  padding: 18px 16px;
  border-radius: 12px;
  margin: 0 0 16px 0;
}
.memb_mult_field_main:last-child {
  margin: 0;
}
.member_field_left h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #101828;
}
.mem_field_bages {
  padding: 6px 12px;
  border: 1px solid #f79009;
  border-radius: 999px;
  background-color: #fffaeb;
  color: #344054;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 0 12px;
  cursor: pointer;
}
.member_field_right h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #98a2b3;
}
.member_field_right button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  color: #344054;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
}
.progress_comp_line {
  background-color: #d0d5dd;
  height: 6px;
  width: 80%;
  border-radius: 100px;
}
.progress_blue_line {
  background-color: #2342bf;
  height: 6px;
  width: 40%;
  border-radius: 100px;
}
.vital_form_progress {
  margin: 0 0 32px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress_step {
  display: flex;
  align-items: center;
}
.num_step {
  margin: 0 0 0 12px;
  color: #0a0d14;
  font-size: 12px;
  font-weight: 600;
}
.vital_form_progress_inner h4 {
  font-size: 16px;
  font-weight: 600;
  color: #344054;
}
.vital_form_progress_inner {
  width: 80%;
}
.grade_content h2 {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 10px 0;
}
.activity-sec ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.activity-sec ul li {
  width: auto;
  background: transparent;
  border: 0;
  margin: 0 10px 10px 0;
  padding: 0;
  position: relative;
}
.activity-sec ul li .btn {
  font-size: 16px;
  font-weight: 500;
  color: #475467;
  background-color: #f2f4f7;
  border: none;
}
.activity-sec ul li .btn:hover {
  color: #475467;
  background: #f2f4f7;
}
.btn-check:checked + .btn:hover {
  color: #fff;
  background-color: #2342bf;
}
.member_vitalinfo_stepone .sap-card-title {
  margin: 0 0 0 12px;
}
.member_vitalinfo_stepone_title {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.member_vitalinfo_stepone_title h5 {
  color: #0a0d14;
  font-weight: 600;
  margin: 0;
}
.member_vital_basic_title h4 {
  color: #0a0d14;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px 0;
}
.vitlinfo_step_onebtn {
  display: flex;
  justify-content: space-between;
}
.vital_stepone_para p {
  font-size: 14px;
  color: #667085;
  font-weight: 400;
}
.stepone_remove {
  text-align: end;
  display: block;
  color: #0a0d14 !important;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.vital_stepone_forth {
  padding: 0 0 30px 0;
}
.vital_stepone_forth .sap-card-title {
  margin: 0 12px 0 0;
}
.vital_stepone_forth .steps-progress {
  width: 100%;
}
.stepone_health_planbtn {
  margin-top: 20px;
}
.stepone_health_planbtn button {
  background-color: #eff4ff;
  padding: 8px 12px;
  border-radius: 12px;
  border: none;
  margin: 0 0 30px 0;
  font-weight: 500;
  color: #667085;
}
.stepone_health_planbtn button svg {
  vertical-align: unset !important;
}
.load_img {
  height: 48px;
  width: 48px;
  border-radius: 200px;
  background-color: #dcfae6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.vital_modal_sub_title h5 {
  font-size: 18px;
  color: #101828;
  margin: 0 0 4px 0;
}
.vital_modal_sub_title p {
  margin: 0 0 24px 0;
}
.vital_modal_sub_cont .accordion-button {
  padding: 16px 0;
}
.vital_modal_sub_cont .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #101828;
}
.vital_modal_sub_cont .accordion-item {
  border: none;
}
.vital_modal_sub_cont .comp_badge {
  border: 1px solid #17b26a;
  background-color: #ecfdf3;
}
.member_vital_form_cont .comp_badge {
  border: 1px solid #17b26a;
  background-color: #ecfdf3;
}
.member_vital_form_cont .in_progress_badge {
  border: 1px solid #173bb2;
  background-color: #ecfdf3;
}
.ddd_steptwo_para {
  padding: 12px 14px;
  border-radius: 12px;
  margin: 0 0 16px 0;
}
.ddd_steptwo_para p {
  padding-bottom: 16px;
  margin: 0;
  font-weight: 400;
  color: #101828;
}
.ddd_steptwo_para p:last-child {
  padding-bottom: 0;
}
.steptwo_mprofile .sap-alert-heading {
  margin: 0 0 32px 0;
}
.stepthree_pref_head {
  margin: 26px 0 0 0;
  color: #475467;
}
.strenght_stepthree_tab .sap-alert-heading {
  margin: 0 0 32px 0;
}
.mjohan_cont {
  padding-top: 60px;
}
.empty-sec .hd-subtitle {
  width: 394px;
  margin: 0 auto;
  padding: 0 0 20px 0;
}
.medicat_tb_contt .appointment-member span.name {
  margin-right: -24px;
  background: #fff;
}
/* ==========================member table css code 18 july============ */

.saf-img-upload.edit-member-sec {
  justify-content: center;
}
.saf-img-upload.edit-member-sec button {
  color: #2342bf;
}
.saf-img-upload.edit-member-sec {
  justify-content: center;
}
.saf-img-upload.edit-member-sec button {
  color: #2342bf;
}
.saf-img-upload.edit-member-sec .mavtar_img {
  position: relative;
}
.saf-img-upload.edit-member-sec .mavtar_img .upload-icon-sec {
  position: absolute;
  top: 18%;
  left: 0;
  right: 0;
  display: none;
  cursor: pointer;
}
.saf-img-upload.edit-member-sec .mavtar_img .upload-icon-sec svg {
  width: 50px;
  height: 50px;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid #2342bf;
}
.saf-img-upload.edit-member-sec .mavtar_img .upload-icon-sec svg path {
  stroke: #2342bf;
}
.saf-img-upload.edit-member-sec .mavtar_img:hover .upload-icon-sec {
  display: block;
}
.begin_modal_btn button {
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  border: none;
}
.begin_modal_btn button span {
  background-color: #2342bf;
  padding: 10px 8px;
  border-radius: 8px;
  margin: 0 10px 0 0;
}
.begin_modal_btn .orang span {
  background-color: #f79009;
}
.modal_h {
  padding: 20px;
}
.modal_h h4 {
  margin: 0;
  font-size: 18px;
}

.flag_icon {
  position: absolute;
  left: 5px;
}
.flag_main {
  display: flex;
  /* align-items: center; */
}
.flag_main .css-13cymwt-control {
  width: 100% !important;
  max-width: 125px !important;
  min-width: 125px !important;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 44px;
  outline: none !important;
}
.flag_main input {
  width: 65%;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.flag_main input:focus {
  border-color: #ccc;
}
.flag_main .css-t3ipsp-control {
  width: 100% !important;
  max-width: 125px !important;
  min-width: 125px !important;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 44px;
  outline: none !important;
  box-shadow: none;
  border: 1px solid #ccc !important;
  border-right: none !important;
}

.rdrCalendarWrapper {
  padding: 10% 0 0 0;
}
.imageDisplay {
  width: 40%;
  margin: 10px;
}

.imageDisplay img {
  width: 100%;
}
.btn-right {
  text-align: right;
}
.calander-icon {
  position: absolute !important;
  top: 5%;
  right: 0;
  border: none;
}
.calander-icon:hover {
  border: none;
  background-color: #fff;
  color: #000;
}
.checks-conatiner {
  display: flex;
  gap: 20px;
}

/* Loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Add a semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 35px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(farthest-side, #000 94%, #0000);
  background: var(--_g) 0 0, var(--_g) 100% 0, var(--_g) 100% 100%,
    var(--_g) 0 100%;
  background-size: 40% 40%;
  animation: l38 0.5s infinite;
}
@keyframes l38 {
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}

/* ================================================Mar css code==================================== */
.medicat_ser {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 0 0 24px 0;
  align-items: center;
}
.medicat_tb_cont tbody tr td .green {
  background-color: #abefc6;
  color: #074d31 !important;
}
.medicat_tb_cont tbody tr td .yellow {
  color: #7a2e0e !important;
}
.medicat_tb_cont tbody tr td .pink {
  background-color: #fecdca;
}
.medicat_tb_cont tbody tr td a {
  cursor: pointer;
}
.medicat_tb_cont .table-profile svg {
  cursor: pointer;
}
.adminstrat_btn_main {
  display: flex;
  justify-content: space-between;
}
.adminstrat_btn button:last-child {
  margin: 0 0 0 12px;
}
.new_medi_modal {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.new_medi_modal.show .modal-dialog {
  top: 0%;
}
.new_medi_modal .modal-title {
  border: 1px solid #eaecf0;
  border-radius: 15px;
  padding: 10px 15px;
}
.star_mult {
  color: #f79009;
}
.medicat_popup_head {
  background-color: #f5f8ff;
  padding: 27px 15px;
  color: #0a0d14;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
}
.mar_pending .table-btn-sec {
  flex-direction: row-reverse;
}
.pending_sec .breadcrumb-item + .breadcrumb-item::before {
  color: #2342bf;
}
.pending_sec .breadcrumb-item + .breadcrumb-item:nth-child(2) {
  color: #2342bf;
  cursor: pointer;
}
.pending_sec .mjohan_cont button {
  margin: 16px 0;
}
.mar_list_left_menu ul li {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 16px 0;
}
.mar_list_left_menu ul li div {
  color: #101824;
  font-weight: 600;
}
.mar_list_left_menu ul li .span_pend {
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  padding: 2px 6px 2px 16px;
  position: relative;
}
.mar_list_left_menu ul li .span_pend::before {
  content: " ";
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 50px;
  background-color: #d0d5dd;
  top: 10px;
  left: 5px;
}
.mar_list_left_menu ul li .span_approv {
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  padding: 2px 6px 2px 16px;
  position: relative;
}
.mar_list_left_menu ul li .span_approv::before {
  content: " ";
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 50px;
  background-color: #0cee1f;
  top: 10px;
  left: 5px;
}
.list_admin_head {
  color: #bf4708;
}
.approve_left {
  background-color: #d1e0ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 0 0 20px 20px;
}
.approve_head {
  font-size: 14px;
  font-weight: 400;
  color: #475467;
  margin: 0 0 2px 0;
}
.approve_pend {
  font-size: 12px;
  font-weight: 600;
  color: #344054;
  position: relative;
  padding: 0 0 0 10px;
}
.approve_left button {
  background-color: #f79009;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  border: 1px solid #eff0c7;
  padding: 2px 12px;
  border-radius: 6px;
}
.approve_pend::before {
  content: " ";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #d0d5dd;
  top: 5px;
  left: -3px;
  border-radius: 50px;
  border: 1px solid #fcfcfd;
}
.approve_approv {
  font-size: 12px;
  font-weight: 600;
  color: #344054;
  position: relative;
  padding: 0 0 0 10px;
}
.approve_approv::before {
  content: " ";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #0cee1f;
  top: 5px;
  left: -3px;
  border-radius: 50px;
  border: 1px solid #0cee1f;
}
.open_mar_sec .mjohan_cont {
  margin: 0 0 30px 0;
}
.open_mar_sec .mjohan_cont .mavtar_img {
  margin: 0 0 10px 0;
}
.open_mar_sec .medicat_ser {
  margin: 30px 0;
}
.open_record_data {
  margin: 30px 0;
  display: flex;
  align-items: center;
}
.home_icon {
  background-color: #dcfae6;
  height: 48px;
  width: 48px;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 0 0;
}
.open_record_head h3 {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 6px 0;
}
.open_record_head h4 {
  font-size: 14px;
  font-weight: 400;
  color: #475467;
}
.open_mar_tbl {
  background-color: #f5f8ff;
  padding: 24px;
}
.open_mar_tbl h2 {
  font-size: 20px;
  font-weight: 600;
  color: #0a0d14;
}
.tbl_num_mar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px 0;
}
.tbl_num_mar {
  color: #2342bf;
  font-size: 20px;
  font-weight: 700;
}
.open_mar_tbl .table-sec .table thead tr th {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
}
.open_mar_tbl .table-sec .table tbody tr td {
  color: #101828;
  font-size: 14px;
  font-weight: 600;
  white-space: break-spaces;
}
.open_inst {
  background-color: #d1e0ff;
  padding: 16px 24px;
}
.open_inst h4 {
  color: #101828;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.open_inst p {
  margin: 0;
  color: #475467;
  font-size: 14px;
  font-weight: 400;
}
.mar_medi_record {
  margin: 0 0 40px 0;
}
.cont_img img {
  width: 100%;
}
#layoutSidenav {
  display: block !important;
}
.approv_dot {
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  padding: 6px 6px 6px 21px;
  position: relative;
  margin: 0 0 0 10px;
}
.approv_dot::before {
  position: absolute;
  content: " ";
  top: 10px;
  left: 5px;
}
.lower {
  text-transform: unset !important;
}
.edit_mar_decs {
  text-align: center;
}
.month-navigation {
  text-align: center;
  margin-top: 20px;
}
.month-navigation span {
  margin: 20px;
}
.add_medication_button {
  text-align: right;
}
.add_medication_button button {
  font-size: 14px;
  font-weight: 600;
  border: none;
  text-decoration: underline;
  color: #333333;
  background: #fff;
}
.mar_delete {
  text-align: right;
}
.medicat_tb_cont tbody tr td .pending {
  color: #2342bf;
  font-weight: 600;
  background: #fff !important;
  cursor: not-allowed;
}
/* ================================================Mar css code==================================== */

/* ================= MAR Calander ==============*/
.td_background {
  border: #fff solid 1px;
}
.mar_table tr td {
  border: #fff solid 2px;
}
.mar_calander {
  overflow: auto;
}
.due_time {
  color: #b54708 !important;
}

.search_ind .left-side-content {
  position: relative;
}
.search_ind input {
  padding: 0% 2% 0% 13% !important;
}
.search_ind svg {
  position: absolute;
  top: 57%;
  left: 4%;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 48px;
}
.mt-18 {
  margin-top: 18px;
}
.tooltip {
  visibility: hidden;
  background-color: #030303;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: -35%; /* Adjust based on your needs */
  /* left: 50%; */
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  width: 160px;
  right: 20px;
}
.tooltip p {
  margin: 0 !important;
}
.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.mar_modal .modal-content {
  padding: 20px;
}
.mar_modal .modal-header button.btn-close {
  top: 3% !important;
}
.mar_line {
  margin-top: 20px !important;
}
.approve_head.text_bold {
  font-weight: bold !important;
}
.table-profile .mar_col_head {
  padding: 0px 6px !important;
}

/* ================= MAR Calander ==============*/

/* ==============Appointment Page css ======== */

.appointment_crose_icon {
  text-align: right;
  font-size: 14px;
}
.rbc-agenda-empty {
  width: 50%;
  margin: 15% auto 0 auto;
  text-align: center;
}
.appointment_dash_sec .apoint_img img {
  width: 100%;
}
.saf-img-upload {
  justify-content: flex-start;
}

.appoint_ment .appointment_modal .apnorht_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.appoint_ment .appointment_modal .apnorht_main .apnorht_head {
  display: flex;
  align-items: center;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}
.appointment_modal .apnorht_head .apnorht_home {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dcfae6;
  margin-right: 15px;
}
.appoint_ment .modal-header {
  margin: 0;
  justify-content: end;
}
.appoint_ment .modal-header button.btn-close {
  margin: inherit;
}
.appoint_ment .appointment_modal .ortho_item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #eaecf0;
  background: #f9fafb;
  padding: 16px;
  border-radius: 8px;
}
.appointment_modal .ortho_item ul li {
  list-style: none;
  margin-bottom: 19px;
}

.appointment_modal .ortho_item ul li .apnman {
  display: inline-block;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  border: 1px solid var(--Colors-Border-border-primary, #d0d5dd);
  background-color: #fff;
  padding: 3px 8px;
  border-radius: 8px;
  color: #344054;
  margin-left: 7px;
}
.appointment_modal .ortho_item .badges {
  margin-top: 10px;
}
.appointment_modal .ortho_item .badges span.pending {
  background-color: #ff692e !important;
}
.appointment_modal .ortho_item .badges span.approved {
  background-color: #17b26a !important;
}
.apn_chng h4.sch {
  /* text-align: center; */
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
  text-transform: capitalize;
}
.apn_chng h4.sch span {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.apn_chng h4.sch span.pending {
  background: #b54708;
}
.apn_chng h4.sch span.approval {
  background: #17b26a;
}
.apn_chng h4.sch span.scheduled {
  background: #86cb3c;
}
.apn_chng h4.sch span.notScheduled {
  background: #f97066;
}
.apn_chng h4.sch span.rescheduled {
  background: #fdb022;
}
.apn_chng h4.sch span.resultsPending {
  background: #2342bf;
}
.apn_chng h4.sch span.completed {
  background: #17b26a;
}
.apn_chng h4.sch span.missed {
  background: #d0d5dd;
}
.apn_chng h4.sch span.declined {
  background: #55160c;
}
.apn_chng h4.sch span.cancelled {
  background: #fecdca;
}

.chang-btn-appoint button {
  background: #f79009;
  padding: 4px 15px;
  color: #fff;
  border: 1px solid #f79009;
  border-radius: 8px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.ortho_item ul .chang-btn-appoint {
  text-align: right;
  margin-top: -42px;
}

.appointment_modal .apnt_resion_cont {
  background: #d1e0ff;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
}

.apnorht_head_inner .text_right_side {
  text-align: right;
}

.appointment_modal .apn_mem_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.appointment_modal .apnt_member .apnt_member_images {
  display: flex;
  align-items: center;
  margin: 7px 0;
}
.appointment_modal .apnt_member {
  margin-top: 12px;
}
.appointment_modal .apnt_member .apnt_member_images h4 {
  margin-left: 8px;
}

.appoint_ment .modal-header .modal-title {
  margin: 0 20px 0 0px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.week_ment {
  display: block;
}
.week_ment .modal-dialog {
  /* margin-right: inherit; */
  margin-right: 61px;
  max-width: 428px;
  margin-top: 208px;
}
.bdr-b {
  margin-left: 20px;
}
.changes_top_btn {
  margin-top: -44px !important;
}
.Profile_curve2 {
  border: 4px solid #ffffff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.appointment-member img {
  margin-left: -8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

.appointment_check {
  margin-right: 11px;
}
.appointment_pluse {
  width: 30px;
  height: 30px;
  top: 3px;
  gap: 0px;
  opacity: 0px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #475467;
  background: var(--Colors-Background-bg-tertiary, #f2f4f7);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -7px;
}
.approval-status-tbl span.pending {
  background: #b54708;
}
.approval-status-tbl span.approval {
  background: #17b26a;
}
.approval-status-tbl span.scheduled {
  background: #86cb3c;
}
.approval-status-tbl span.notScheduled {
  background: #f97066;
}
.approval-status-tbl span.rescheduled {
  background: #fdb022;
}
.approval-status-tbl span.resultsPending {
  background: #2342bf;
}
.approval-status-tbl span.completed {
  background: #17b26a;
}
.approval-status-tbl span.missed {
  background: #d0d5dd;
}
.approval-status-tbl span.declined {
  background: #55160c;
}
.approval-status-tbl span.cancelled {
  background: #fecdca;
}

.board_view {
  display: inline-block;
  text-align: center;
  padding: 17px;
  border: 1px solid var(--Colors-Border-border-primary, #d0d5dd);
  background: var(--Colors-Background-bg-active, #f9fafb);
  border-radius: 6px;
}
.board_view-list {
  border: 1px solid #2342bf;
  background: #d1e0ff;
  margin-left: 40px;
}
.entry_box {
  padding: 20px;
  border-bottom: 1px solid #00000014;
}
.modal-week {
  text-align: right;
  margin: 10px 10px 0 0;
}

.weekends {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 40px 20px;
}

.show_week input {
  height: 20px;
}
.week_list {
  margin-top: 14px;
}

.appointment_new .form-create {
  max-width: 750px;
}
.doctor_text {
  display: flex;
  align-items: center;
  padding: 20px;
  background: #f5f8ff;
}

.apnorht_header_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px;
  border-bottom: 1px solid #00000012;
}
.appointment_select {
  padding: 0 20px 20px 20px;
}
.doctor_text .dc_dt {
  margin-right: 16px;
}
.reason_for_appointment {
  padding: 0 20px;
}
.reason_for_appointment .reason_for_appointment_text {
  padding: 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  margin-top: 14px;
}
.search-sect {
  position: relative;
}
/* .appointment_select .search-sect svg{
    position: absolute;
    top: 0;
    left: 0;
    } */
.appointment_select .search-sect input {
  padding: 0 30px;
}
.appointment_select .search-sect svg {
  position: absolute;
  top: 14px;
  left: 19px;
}
.appointment_select .search_icon_s {
  position: absolute !important;
  right: 53px;
  top: 1px;
}
.search_active {
  padding: 0px 20px 20px 20px;
}
.search_active button {
  margin-top: 10px;
  background: #f5f8ff;
  border: none;
  border-radius: 12px;
}

.save_changes_btn button {
  width: 100%;
}

.cancel_save {
  padding: 20px;
}
.change_status_btn button {
  width: 100%;
}
.change_status_btn {
  padding: 20px;
}

.apnorht_home.appointment_top_icon {
  background: #dcfae6;
  /* padding: 20px; */
  border-radius: 50px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete_box {
  padding: 0 20px 20px 20px;
}
.cancel_delete {
  text-align: right;
  padding: 15px 10px;
}
.delete_model_text {
  margin: 10px 0;
}
.apmt-hdg {
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid #76767640;
}
.appointment_cont {
  margin: 0 20px 20px 20px;
  padding: 20px;
  background: #f5f8ff;
  border-radius: 8px;
}
.patient_history {
  padding: 20px;
}
.appointment-hr-btn {
  margin-top: -43px !important;
}
.appointment_status {
  display: block;
}
.appoint_ment {
  display: block;
}
.delete_model_sec {
  display: block;
}
.rbc-month-row {
  overflow: unset !important;
  height: unset !important;
}
.rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 0 !important;
  background-color: #fff !important;
  border-radius: 5px;
  color: #000 !important;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.event_div {
  padding: 12px 8px;
  border-radius: 10px;
  height: 100%;
}
.event_div .profile-sec span.name {
  width: 20px;
  height: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  font-size: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0 0px;
  font-weight: 700;
  margin-left: -10px;
  background: #fff;
}
.event_div .profile-sec .appointment_plus {
  margin-left: -10px;
  background: #f2f4f7;
  border: 1px solid skyblue;
  border-radius: 50px;
  padding: 2px 3px;
  font-size: 10px;
  color: #818489;
  z-index: 9;
}
.event_div .header_event .profile-sec {
  display: flex;
}
.event_div .profile-sec span.name img {
  width: 20px;
  height: 20px;
  margin: 0;
  border-radius: 50%;
  object-fit: cover;
}
.event_div h4 {
  font-size: 14px;
  color: #101828;
}
.event_div p {
  font-size: 12px;
  color: #475467;
}
.event_div .appointment-spacer {
  margin-top: 20px;
}
.rbc-day-slot .rbc-event {
  border: unset !important;
}
.rbc-day-slot .rbc-event-label {
  display: none !important;
}
.rbc-timeslot-group {
  min-height: 100px !important;
}
.rbc-day-slot .rbc-events-container {
  margin-right: 0 !important;
}
.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
}
.react-pdf__Page__textContent {
  height: 100% !important;
  width: 100% !important;
}
.pdf_div button {
  border: none !important;
  background-color: unset !important;
}
.appointment_table tbody .table-profile .profile-sec .appointment-member .name {
  color: #475467;
  margin-right: -10px !important;
  background: #fff;
}

.appointment-member {
  position: relative;
  display: inline-block;
}

.name {
  cursor: pointer; /* Shows a pointer cursor on hover */
}

.appointment_table .profile-sec .appointment-member .name::after {
  content: attr(title);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  bottom: 100%; /* Position above the image */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
}

.name:hover::after {
  visibility: visible;
  opacity: 1;
}
.apnt_member {
  display: flex;
}
.apnt_member .appointment-member {
  display: flex;
  margin: 5px;
  /* background-color: red;
  border-radius: 50px; */
}
.status_div {
  display: flex;
}
.status_button_appointment {
  background: #f79009;
  padding: 4px 15px;
  color: #fff;
  border: 1px solid #f79009;
  border-radius: 8px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-left: 10px;
}
.date_picker_container {
  position: relative;
}
.date_picker_under {
  position: absolute;
  z-index: 999;
  top: 79%;
  border: 1px solid #d0d5dd;
}
.appointment_table .appointment_plus {
  background: #f2f4f7;
  border: 1px solid #dddddd;
  border-radius: 50px;
  padding: 5px 6px;
  font-size: 20px;
  color: #818489;
  z-index: 9;
  width: 37px;
  height: 37px;
  font-size: 14px;
}

.edit_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.right_div .role {
  display: flex;
  align-items: center;
}
.right_div .apnman {
  border: 1px solid #00000036;
  border-radius: 6px;
  margin-left: 14px;
  padding: 3px 11px;
  font-weight: 400;
}
.document_option .appointment-member {
  display: flex;
}
.address_div {
  background: #fff;
  border: 1px solid #ced4da;
  padding: 5px;
  max-height: 200px;
  overflow: scroll;
}
.address_div ul {
  list-style-type: none;
}
.address_div ul li {
  font-size: 14px;
}
.appointment_dash_sec .date_picker_container .saf-img-upload {
  justify-content: space-between !important;
}
/* ==============Appointment Page css ======== */
/* ================= Communication CSS ==============*/

.communication_hg {
  background: #fef0c7;
  border-radius: 50px;
  margin-right: 11px;
  color: #7a2e0e;
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.communication_dj {
  background: #abefc6;
  border-radius: 50px;
  margin-right: 11px;
  color: #074d31;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.communication_ga_p {
  background: #fecdca;
  border-radius: 50px;
  margin-right: 11px;
  color: #7a271a;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.communication_mg {
  background: #d3f8df;
  border-radius: 50px;
  margin-right: 11px;
  color: #084c2e;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
span.communication_mh {
  background: #d1e0ff;
  border-radius: 50px;
  margin-right: 11px;
  color: #00359e;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_hg {
  margin: 0 auto;
  margin-top: 20px;
}
.profile_hg span {
  display: inline-block;
  height: 120px;
  width: 120px;
  border-radius: 8px;
  background: #d3f8df;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #084c2e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages_icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0;
  border-bottom: 1px solid #eaecf0;
}
.members {
  display: flex;
  align-items: center;
  gap: 11px;
}
.members p {
  line-height: 18px;
}
.members_svg {
  background-color: #d1e0ff;
  height: 38px;
  width: 38px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.program_name {
  padding: 12px 0;
  border-bottom: 1px solid #eaecf0;
}

.tabs_sec_comm {
  max-width: 230px;
  width: 100%;
  display: block !important;
}
.tabs_sec_comm .tabs_sec_comm_btn {
  color: #344054 !important;
}
/* .tabs_sec_comm .tabs_sec_comm_btn:hover{
	border-radius: 0;
	background: #F9FAFB !important;
	border-left: 1px solid #2342BF;
	color: #344054 !important;
} */
.tabs_sec_comm .tabs_sec_comm_btn.active {
  border-radius: 0;
  background: #f9fafb !important;
  border-left: 1px solid #2342bf;
  /* color: #344054 !important; */
}

.tabs_sec_comm_btn .oln {
  width: 8px;
  height: 8px;
  border-radius: 18px;
  background: #17b26a;
  margin: 0 18px -27px -23px;
}

.tabs_sec_comm_btn .ofn {
  width: 8px;
  height: 8px;
  border-radius: 18px;
  background: #dc6803;
  margin: 0 18px -27px -23px;
}
.tabs_sec_comm_btn .ga_oln {
  width: 8px;
  height: 8px;
  border-radius: 18px;
  background: #b42318;
  margin: 0 18px -27px -23px;
}
.communication_logs {
  width: 100%;
}
.pdf_image img {
  margin: 14px 14px 0 0;
}

.member_sectio {
  display: flex;
}
.member_comm {
  padding: 12px 0px 12px 0px;
  border-bottom: 2px solid #2342bf;
  margin-bottom: 14px;
}

.jospine_greta_icon {
  margin-top: -40px;
}
.apnt_gym {
  background: #d1e0ff;
  padding: 10px;
  border-radius: 8px;
}
.apnorht_msg {
  gap: 18px;
  align-items: center;
  border-bottom: 2px solid #d1d1d1;
  padding-bottom: 18px;
  margin-bottom: 19px;
  display: flex;
}

.apnorht_msg_icon {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dcfae6;
}

.documents_file_pdf {
  border: 1px solid #eaecf0;
  background: #f9fafb;
  padding: 14px;
  border-radius: 8px;
}
.documents_file_pdf .brain_image p {
  width: 100%;
  overflow: hidden;
}
.documents_file_pdf .brain_image .file_type {
  text-transform: uppercase;
  color: #fff;
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 600;
}
.documents_file_pdf .brain_image .file_type.msword {
  background: #3eff13;
}
.documents_file_pdf .brain_image .file_type.doc {
  background: #3eff13;
}
.documents_file_pdf .brain_image .file_type.xls {
  background: #3eff13;
}
.documents_file_pdf .brain_image .file_type.pdf {
  background: #ff1313;
}
.documents_file_pdf .brain_image .file_type.png {
  background: #ff1313;
}
.documents_file_pdf .brain_image .file_type.jpeg {
  background: #1129fb;
}
.documents_file_pdf .brain_image .file_type.jpg {
  background: #1129fb;
}

.brain_image {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--Colors-Background-bg-primary, #ffffff);
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  margin-left: 10px;
}

.samples_png {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 15px;
}
.samples_png .brain_image p {
  margin: 0px !important;
}
.msg button.btn-close {
  margin: inherit;
}
.msg .modal-header {
  justify-content: space-between;
  margin: 0;
}
.msg .modal-title {
  margin: 0 20px 0 0px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.msg {
  max-width: 688px;
}
.deleted_msg {
  max-width: 600px;
}
.cancel_delete {
  text-align: right;
}
.date_capitalize {
  text-transform: capitalize !important;
}
.mjohan_contt {
  text-align: center;
}
.mjohan_contt p {
  text-transform: capitalize;
}
.margin_header {
  margin: 20px !important;
}
.communication_edit_modal .modal-header {
  justify-content: end !important;
}
/* ================= Communication CSS ==============*/

/* ================= Documents CSS ==============*/
.appointment_selects .search-sect svg {
  position: absolute;
  top: 0;
  left: 0;
}
.appointment_selects .search-sect input {
  padding: 0 30px;
}
.appointment_selects .search-sect svg {
  position: absolute;
  top: 13px;
  left: 20px;
}
.appointment_selects .search_icon_s {
  position: absolute !important;
  right: 53px;
  top: 1px;
}
.document_option {
  display: flex;
}
.document_btn button {
  padding: 8px 38px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #344054;
  margin: 20px;
}
.document_btn button svg {
  margin-right: 5px;
  margin-top: -3px;
}

.doc_info_data .table-sec .table td:last-child {
  text-transform: none;
}
.doc_info_data .table-sec .table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  color: #101828;
}
.doc_info_data .table-sec .table tbody tr td span {
  color: #475467;
  margin-right: -18px !important;
  background: #fff;
}
.bg-white {
  border-radius: 50%;
  background: #fff;
}
.doc_info_data .table-sec .table tbody tr td a {
  color: #2342bf;
  font-weight: 600;
}
.pages_align {
  text-align: center;
}
.margin_header h2 {
  font-size: 20px;
}
.image_div {
  width: 80%;
  margin: auto;
}
.image_div img {
  width: 100%;
}
.member_container {
  max-height: 300px;
  width: 97%;
  margin: auto;
  overflow-y: scroll;
  border: 1px solid #ced4da;
}
.member_container ul {
  list-style-type: none;
}
.document_option .appointment-member .profile-info p span svg {
  background: unset !important;
}
.document_option_doc {
  display: inline-flex;
  margin: 20px 0px 0 0;
  background: #f5f8ff;
  padding: 10px;
  border-radius: 12px;
}
.document_option_doc .profile-sec {
  padding: 0;
}
.document_option_doc .profile-info {
  padding: 5px 0;
}
.document_option_doc .crose_div {
  padding: 3px 0;
  margin-left: 8px;
}
.medication_modall {
  background: #e9eaed !important;
}
.member_container img {
  border-radius: 50px;
  height: 100%;
  width: 100%;
}
.member_container li {
  margin: 20px 10px;
}
.profile-sec {
  padding-right: 7px;
}
.member_li {
  display: flex;
  align-items: center;
}
.member_li .name {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0 0px;
  font-weight: 700;
}
/* ================= Documents CSS ==============*/

/* =========== Task css start here ============== */
.task_search {
  width: 320px;
}
.task_search_box {
  position: relative;
}
.task_search_box input {
  padding-left: 36px;
}
.task_search_box svg {
  position: absolute;
  top: 12px;
  left: 12px;
}

.task-information .task-heading h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
}
.tasks-section .task-pending-sec .task-information .task-heading h3 span {
  padding: 3px 6px;
  background-color: #000;
  color: #ffff;
  border-radius: 45%;
  margin-left: 6px;
  font-size: 18px;
}
.tasks-section .task-information .task-content h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: rgba(52, 64, 84, 1);
}
.tasks-section .task-information .task-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(52, 64, 84, 1);
}
.tasks-section .task-information .task-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tasks-section .task-information .task-footer p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(71, 84, 103, 1);
}
.tasks-section .task-pending-sec {
  border: 1px solid #b3b8db;
  border-radius: 12px;
  padding: 10px;
  background: #f2f4f7;
  /* height: 595px !important; */
  /* overflow-y: scroll; */
  min-height: 570px !important;
}
.pending_task {
  border: 1px solid #b3b8db;
  border-radius: 12px;
  padding: 10px;
  background: #f2f4f7;
}
.task-content {
  background: #ffffff;
  margin-top: 17px;
  padding: 10px;
  border-radius: 10px;
  min-height: 150px;
}
p.task_para_space {
  margin: 5px 0 20px 0;
}
.see_morebtn {
  text-align: center;
  padding: 10px;
}
.see_morebtn button {
  border: 0;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #344054;
  background: transparent;
}

.task_po {
  max-width: 600px;
}
.task_heading {
  position: relative;
}
.h_task {
  position: absolute;
  top: -42px;
  left: 16px;
}
.past_due {
  background-color: #fee4e2 !important;
  border: 1px solid #d92d20 !important;
  /* height: 100%; */
}
.pending_approval {
  background-color: #fef0c7 !important;
  border: 1px solid #dc6803 !important;
  /* height: 100%; */
}
.task_completed {
  border: 1px solid #3b7c0f !important;
  background: #e3fbcc !important;
}

.tasks-section .task-information .task-footer img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.tasks-section .task-information .task-footer .name {
  width: 32px;
  height: 32px;
  background: #fff;
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
  padding: 3px;
}

/* .status_modal_contant {
  display: flex;
  
}
.status_modal_decs{
  margin-top: 20px;
  margin-bottom: 20px;
}
.status_modal_contant.footer{
  justify-content: flex-end;
}
.status_modal_contant.footer .footer_button{
  margin: 10px;
} */
.status_modal_decs {
  display: flex;
  gap: 20px;
}
.status_modal_contant {
  display: flex;
  gap: 20px;
  align-items: center;
}
.status_modal_contant .task_status {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #667085;
}
.status_modal_decs .task_status {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #667085;
}
.status_modal_contant p {
  margin-bottom: 32px;
}
/* p.pending.approval {
  margin-top: -24px;
} */
.status_modal_contant .right .task-footer {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  margin-top: -8px;
}
.status_modal_contant .right .task-footer .name {
  width: 32px;
  height: 32px;
  background: #fff;
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
  padding: 3px;
  margin-right: 10px;
}
.status_modal_contant .right .task-footer p {
  margin-bottom: 0px !important;
}
.tatus_modal_contant .right p,
.task_description {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #101828;
  height: 170px;
  overflow-x: auto;
}
.task_fobtn {
  padding-right: 14px !important;
}
.task_table_action p {
  color: #2342bf;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
}
.status_modal_contant .right .pending {
  background: #b3b8db !important;
  padding: 5px;
}
.status_modal_contant .right .past.due {
  background-color: #fee4e2 !important;
  padding: 5px;
}
.status_modal_contant .right .pending.approval {
  background-color: #fef0c7 !important;
  padding: 5px;
  margin-bottom: 0px I !important;
}
.status_modal_contant .right .completed {
  background: #e3fbcc !important;
  padding: 5px;
}
.btn_min_width {
  min-width: 120px !important;
}
.saf-img-upload.task_header {
  justify-content: space-between !important;
}
.btn-see-more {
  width: 100%;
  padding: 2px;
  background: #ffffff00;
  border: unset;
  margin: 10px 0;
  font-weight: bold;
  color: #344054;
  font-size: 16px;
}
.taskpaddung_flex {
  font-size: 15px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.taskpaddung_flex span {
  background-color: #000;
  color: #fff;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.task_list_view .table-profile {
  padding: 0px !important;
}
.task_list_view .table-profile .profile-sec {
  padding: 0 !important;
}
.task_Status_modal {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.task_Status_modal .task_po {
  top: 25%;
}

.sidebar_count {
  background: #ff6938;
  padding: 5px;
  border-radius: 50%;
  width: 32px;
  text-align: center;
  margin-left: auto;
  color: #fff;
}
.no_task_notifi {
  text-align: center;
}
.taxt_cap {
  text-transform: capitalize !important;
}
.task_dropdown_view {
  margin-bottom: 35px;
}
.width_100 {
  width: 100px;
}
.task_Status_modal .task_heading {
  padding-bottom: 0px !important;
}
.task_Status_modal .apnorht_head {
  margin-bottom: 20px;
}
.task_list_status .pending {
  background: #98a2b3 !important;
}
.task_list_status .past.due {
  background: #d92d20 !important;
}
.task_list_status .pending.approval {
  background: #dc6803 !important;
}
.task_list_status .completed {
  background: #3b7c0f !important;
}
.task_delete_popup {
  max-width: 500px !important;
}
.task_delete_popup .delete_box {
  padding: 0px !important;
}
.status_modal_contant .right .task-footer img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
}
.new_medi_modal.show .task_po {
  top: 15% !important;
}
/* =========== Task css end here ============== */

/*================Notification css start here======*/

.f_weight {
  font-weight: 500;
}

.notifivation_member li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #d0d5dd;
  padding: 10px 10px 10px 0px;
  cursor: pointer;
}
.notifivation_member .unread_mag_bc {
  background: #f5f8ff;
}
.notifivation_member li .notifivation_member_profile {
  background: #eff4ff;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}
.notifivation_member_contant .gray_color {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background: #667085;
  display: inline-block;
  margin: 0 6px 2px 6px;
}
.notifivation_member {
  padding: 10px 0px 10px 0px;
}
.tabs_mem {
  margin-top: -30px;
}

.appointment_profile_img {
  margin-left: 12px;
}
.appointment_profile_img img {
  margin-left: -12px;
}
.appointment_profile_img span {
  width: 26px;
  height: 26px;
  border-radius: 50px;
  background: #f2f4f7;
  border: 2px solid #ffffff;
  display: inline-block;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-left: -11px;
}
.mark {
  padding: 0 22px !important;
  margin-top: 4px;
  display: block;
}
.this_is_going {
  font-style: italic;
  border-left: 3px solid #2342bf;
  padding-left: 5px;
}

.unread_msg {
  display: inline-block;
  font-weight: 500;
  color: #2342bf;
  padding: 2px 8px 2px 8px;
  gap: 10px;
  border-radius: 59px;
  background: #d1e0ff;
  justify-content: center;
}

.modal-all_notification {
  text-align: center;
  margin-bottom: 18px;
}
.modal-all_notification p a {
  display: inline-block;
  text-decoration: none;
  color: #000;
}
.notification_popup_align {
  margin-right: 34px;
  margin-top: 90px;
}
.notification_popup_align .notifivation_member {
  height: 200px;
  overflow: auto;
}
.notification_popup_align .modal-content .modal-body h5 {
  text-align: center;
  padding-top: 20px;
}
.new_medi_modal .notification_popup_align .modal-title {
  border: unset !important;
  border-radius: unset !important;
}
.notification_main {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 35px;
  width: 100%;
}
.notification_main .nav-tabs {
  margin: unset !important;
  border-bottom: unset !important;
}
.notification_main .notification_drop {
  display: flex;
  justify-content: flex-end;
}
.notification_main .notificationDropdown {
  width: 40%;
}

/*================Notification css end here======*/

.stripe-element {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out;
}
/* Strip css */

/* Page not found */
.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background: linear-gradient(
    118.09deg,
    rgba(35, 66, 191, 0.3) 0%,
    rgba(0, 0, 0, 0.09) 100%
  );
}
.page-interface {
  width: 100%;
  height: 100vh;
}

.page-interface .page-detail h1 {
  font-size: 54px;
  font-weight: 700;
  margin-top: 30px;
}
.page-interface .page-detail p {
  font-size: 22px;
  line-height: 36px;
  width: 30%;
  margin: 30px auto;
}
.page-interface .page-detail a {
  text-decoration: none;
  padding: 10px 38px;
  border-radius: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
}

.page-interface img {
  width: 25%;
  margin-top: 5%;
}
#shadow-host-companion {
  padding: 0 !important;
}
/* Page not found */

/* Slick slider */
.slick-next {
  right: 0px !important;
}
.slick-next:before {
  color: #000000 !important;
  font-size: 30px !important;
  opacity: 0.75 !important;
}
.slick-prev {
  left: 0px !important;
}
.slick-prev:before {
  color: #000000 !important;
  font-size: 30px !important;
  opacity: 0.75 !important;
}
.debit-card {
  margin: auto !important;
}
.debit-card .card-number-block p {
  color: #607d8b;
  font-size: 20px;
}
.debit-card .card-holder-block p {
  color: #607d8b;
  font-size: 16px;
}

/* =====media query css code======== */

@media (max-width: 1440px) {
  .page-interface .page-detail p {
    font-size: 22px;
    line-height: 32px;
    margin: 20px auto;
    width: 40%;
  }
}

@media (min-width: 1400px) {
  /*.container {max-width: 1396px;}*/
  .sb-sidenav .sb-sidenav-menu .nav .nav-link {
    padding: 10px 10px;
    margin: 0 0 0px;
  }
}

@media (min-width: 1024px) {
  /* task @media css start here */

  .task_responsive_space {
    padding: 5px !important;
  }
  .task-information .task-heading h3 {
    font-size: 15px;
  }
  .tasks-section .task-pending-sec .task-information .task-heading h3 span {
    font-size: 15px;
  }
  .tasks-section .task-information .task-content h4 {
    font-size: 15px;
  }
  .tasks-section .task-information .task-content p {
    font-size: 13px;
  }
  .tasks-section .task-information .task-footer .name {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
  .task-content {
    margin-top: 6px;
    padding: 5px;
  }

  /* task @media css end here */

  .page-interface .page-detail h1 {
    font-size: 48px;
  }
  .page-interface .page-detail p {
    font-size: 22px;
    line-height: 32px;
    margin: 20px auto;
    width: 40%;
  }

  .page-interface .page-detail a {
    font-size: 20px;
    padding: 13px 67px;
  }
}

@media (max-width: 768px) {
  .page-interface .page-detail h1 {
    font-size: 44px;
  }
  .page-interface .page-detail p {
    font-size: 18px;
    line-height: 26px;
    width: 72%;
  }

  .page-interface .page-detail a {
    font-size: 18px;
  }
  .page-interface {
    width: 100%;
    height: unset !important;
  }
}
@media (max-width: 425px) {
  .page-interface .page-detail h1 {
    font-size: 36px;
  }
  .page-interface .page-detail p {
    font-size: 14px;
  }

  .page-interface .page-detail a {
    font-size: 14px;
    padding: 12px 50px;
  }

  .page-interface img {
    width: 77%;
  }
}
@media (max-width: 320px) {
  .page-interface .page-detail p {
    font-size: 14px;
    line-height: 26px;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
    left: 0;
  }
  #layoutSidenav #layoutSidenav_nav {
    flex-basis: 300px;
    transform: translateX(-300px);
  }
  .sb-nav-fixed.sb-sidenav-toggled #layoutSidenav {
    padding: 0 0 0 10px;
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
    margin: 0;
    border-radius: 0 10px 10px 0;
  }
  #layoutSidenav {
    padding: 0 0 0 10px;
  }
  .sb-sidenav-menu {
    padding: 0;
  }
  .new-chat a,
  .sidebar-btn-sec a {
    margin: 20px auto;
  }
  .sb-sidenav-menu.bottom {
    border-radius: 30px 30px 0 0;
  }
  .memb_mult_field_main {
    align-items: start;
    flex-direction: column;
  }
  .member_field_right h4 {
    margin: 12px 0 0 0;
  }
  .member_field_right button {
    margin: 12px 0 0 0;
  }
  .pills-steps {
    flex-direction: column;
  }
  .pills-steps .nav-link {
    min-width: 300px;
    font-size: 18px;
    white-space: nowrap;
  }
  .pills-steps .nav {
    flex-direction: row !important;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;
  }

  /* task @media css start here */
  .task_responsive_space {
    padding: 5px !important;
  }

  /* task @media css start here */
}
@media (max-width: 767px) {
  .sb-topnav .navbar-brand {
    width: 100px;
    padding: 0px !important;
  }
  .sb-topnav .navbar-brand img {
    width: 100%;
    object-fit: contain;
  }
  button#sidebarToggle {
    margin: 0 !important;
  }
  .nav-item.dropdown a {
    margin: 0 10px 0 0;
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
    margin: 0;
  }
  .info-content {
    margin: 0 0 20px;
    height: auto;
  }
  .sb-nav-fixed .sb-topnav {
    padding: 5px 15px;
  }
  .myheader {
    height: 66px;
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_content {
    top: 66px;
    padding-left: 290px;
  }
  #layoutSidenav #layoutSidenav_content {
    min-height: calc(100vh - 66px);
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
    height: calc(100vh - 66px);
    top: 66px;
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
    border-radius: 0 0px 0px 0;
  }
  main {
    padding: 20px 0px 0 0px;
  }
  .sb-nav-fixed .sb-topnav {
    padding: 10px 15px;
    margin: 0px auto 0;
    border-radius: 0;
    width: 100%;
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    z-index: 10;
  }
  .nav-item.dropdown a svg {
    width: 21px;
    height: 21px;
  }
  .nav-tabs button {
    margin: 0 14px 0 0;
    font-size: 14px;
  }
  .right-main-sec {
    padding: 15px;
  }
  .sub-plans-head {
    grid-template-columns: repeat(1, 1fr);
  }
  .role-listing {
    margin: 0 0 15px;
  }
  .left-side-content.searchbar input {
    width: 100%;
  }
  .table-btn-sec .pagination a.page-link {
    font-size: 12px;
    padding: 8px 8px;
  }
  .table-btn-sec {
    margin: 0 0 20px;
    flex-wrap: wrap;
  }
  .table-btn-sec nav {
    margin: 0 0 10px;
  }
  .search-sec {
    display: block;
    margin: 20px 0;
  }
  .left-side-content.searchbar {
    margin: 10px 0 0;
  }
  .g-content-box .content-box {
    width: 100%;
  }
  .graph-content {
    padding: 18px 18px;
    margin: 0 0 15px;
  }
  .home-staff-create.graph-content {
    margin: 15px 0 0;
    gap: 10px;
    padding: 12px;
    cursor: pointer;
  }
  .left-side-content button {
    margin: 0 0 5px;
  }
  .table-sec .table td,
  .table-sec .table th {
    white-space: nowrap;
  }
  .progress-checkbox label {
    font-size: 12px;
  }
  .progress-checkbox {
    margin: 0 0 15px;
  }
  .debit-card {
    width: 300px;
    height: 194px;
    padding: 20px 10px;
    margin: 20px auto;
  }
  .blling-tabs #pills-tab {
    margin: 0 0 15px !important;
    flex-wrap: nowrap;
    width: 100%;
  }
  .blling-tabs button {
    font-size: 13px;
  }
  .sub-plans-toggler form .form-check label {
    padding: 10px 6px;
    font-size: 13px;
  }
  .blling-tabs button svg {
    display: block;
  }
  .blling-tabs button {
    padding: 12px 12px;
  }
  .mem_filt_btn button {
    margin: 15px 0 0 0;
  }
  .vital_form_progress {
    align-items: start;
    flex-direction: column;
  }
  .vital_form_progress_inner {
    width: 100%;
  }
  .member_field_left {
    width: 100%;
  }
  .member_field_left h4 {
    font-size: 14px;
  }
  .mem_field_bages {
    font-size: 12px;
    display: block;
    margin: 12px 0;
    width: 100%;
    text-align: center;
  }
  .three-btn-sec {
    flex-direction: column;
    align-items: center;
  }
  .two-btn-sec {
    flex-wrap: wrap;
    text-align: center;
  }
  .two-btn-sec button {
    margin: 0 12px 12px 0;
  }
  /* ================================================Mar css code==================================== */
  .tbl_num_mar {
    flex-direction: column;
    align-items: start;
  }
  .tbl_num_mar h2 {
    font-size: 16px;
  }
  .medicat_ser {
    flex-direction: column;
  }
  .medicat_ser .form-group input {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .adminstrat_btn_main {
    flex-direction: column;
  }

  /* ================================================Mar css code==================================== */
  /* ================================================appointment css code==================================== */
  .appointment_dash_sec .saf-img-upload {
    flex-direction: column;
  }
  .member_day_main {
    flex-direction: column;
  }
  .member_day_btn {
    flex-direction: column;
  }
  .member_day_btn select {
    margin: 0;
  }
  .ortho_item {
    flex-direction: column;
  }
  .apnorht_main {
    flex-direction: column;
    align-items: start;
  }
  .apnorht_head {
    margin: 0 0 15px 0;
  }
  /* ================================================appointment css code==================================== */

  .tasks-section .task-pending-sec {
    min-height: 400px !important;
  }
}

.show_searchbtn {
  width: 8% !important;
}
.others_docs {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: #f2f4f7;
  border: 1px solid #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #475467;
}
.center {
  align-items: center;
}
.others_docs2 {
  width: 35px;
  height: 35px;
  border: 2px solid #ccc;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0 0px;
  margin-bottom: 5px;
  font-weight: 700;
}
.p-0 {
  padding: 0px;
}
.module_select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.font-weight {
  font-weight: 600;
  font-size: 16px;
}
.width-full-accordian {
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.form-select {
  height: 44px;
}

.filter_container {
  display: flex;
  gap: 22px;
}
.flex-input {
  display: flex;
}
.w-full {
  width: 100%;
}
.mar_show_filter {
  width: 45%;
}
.cvc-input-container {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.cvc-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.cvc-input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  transition: border-color 0.2s ease;
  background-color: white;
}

.cvc-input:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.cvc-input::placeholder {
  color: #9ca3af;
}

.toggle-visibility-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
  transition: color 0.2s ease;
}

.toggle-visibility-button:hover {
  color: #374151;
}

.toggle-visibility-button:focus {
  outline: none;
  color: #374151;
}

.toggle-icon {
  width: 16px;
  height: 16px;
}

/* For better mobile experience */
@media (max-width: 640px) {
  .cvc-input {
    font-size: 16px; /* Prevents zoom on iOS */
    padding: 12px;
    padding-right: 40px;
  }

  .toggle-icon {
    width: 20px;
    height: 20px;
  }
}
.flex-col {
  flex-direction: column;
}

.image-stack {
  display: flex;
  align-items: center;
}

.image-item {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
  margin-left: -14px;
  position: relative;
}

.image-item:first-child {
  margin-left: 0;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.extra-count {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b5563;
  font-weight: 500;
  border: 3px solid #fff;
  margin-left: -14px;
  z-index: 2;
}

.extra-count:first-child {
  margin-left: 0;
}

.extra-count img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
